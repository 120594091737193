import './styles/quasar.scss';
// import lang from 'quasar/lang/zh-TW.js';
import '@quasar/extras/material-icons/material-icons.css';

// To be used on app.use(Quasar, { ... })
export default {
    config: {},
    plugins: {
    },
    // lang,
};
