<template>
    <div class="search">
        <div class="content">
            <div class="title font-bold">
                你想找哪種類型的配音員？
                <span class="sub-title font-bold"
                    >（動畫、戲劇、廣告、廣播、電話語音）</span
                >
            </div>
            <div class="form-box">
                <div class="form">
                    <div class="select-box">
                        <q-select
                            class="select"
                            clearable
                            outlined
                            v-model="form.gender"
                            :options="genderOptions"
                            label="性別"
                        />
                        <q-select
                            class="select"
                            clearable
                            outlined
                            v-model="form.voiceType"
                            :options="voiceTypeOptions"
                            label="聲線種類"
                        />
                        <q-select
                            class="select"
                            clearable
                            outlined
                            v-model="form.langue"
                            :options="langueOptions"
                            label="語種"
                        />
                        <q-select
                            class="select"
                            clearable
                            outlined
                            v-model="form.dubbingType"
                            :options="dubbingTypeOptions"
                            label="配音種類"
                        />
                    </div>
                    <div class="input-box">
                        <q-input
                            class="input"
                            outlined
                            clearable
                            v-model="form.text"
                            placeholder="請輸入關鍵字"
                            @keypress.enter="search"
                        />
                    </div>
                </div>
                <div class="submit">
                    <div class="btn" @click="search">
                        <font-awesome-icon class="search-icon" icon="search" />
                        搜尋</div
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';

export default {
    components: {},
    props: {
        filter: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            form: {
                gender: ref(''),
                voiceType: ref(''),
                langue: ref(''),
                dubbingType: ref(''),
                text: ref(''),
            },
            genderOptions: [
                '男',
                '女',
            ],
            voiceTypeOptions: [
                '小孩',
                '青少年',
                '中年',
                '老年',
                '特殊變音',
            ],
            langueOptions: [
                '國語', '台語', '客語', '英語', '日語', '韓語', '西班牙語',
            ],
            dubbingTypeOptions: [
                '動畫', '戲劇', '廣告', '系統語音/廣播', '電玩', '有聲書', '旁白',
            ],
        };
    },
    computed: {},
    created() {
        this.init();
    },
    mounted() { },
    methods: {
        init() {
            this.form.gender = this.filter.gender;
            this.form.voiceType = this.filter.voiceType;
            this.form.langue = this.filter.langue;
            this.form.dubbingType = this.filter.dubbingType;
            this.form.text = this.filter.text;
        },
        search() {
            this.$emit('search', {
                gender: this.form.gender,
                voiceType: this.form.voiceType,
                langue: this.form.langue,
                dubbingType: this.form.dubbingType,
                text: this.form.text,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.search {
    width: 100vw;
    display: flex;
    justify-content: center;
}
.content {
    width: 80vw;
    max-width: 900px;
    background-color: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    padding: 28px 26px;

    .title {
        font-size: 24px;
        .sub-title {
            font-size: 20px;
        }
    }

    .form-box {
        display: flex;
    }
    .form {
        flex: 3;
        align-items: flex-end;
        .select-box {
            display: flex;
            margin-top: 20px;
            margin-bottom: 20px;

            .select {
                flex: 1;
            }

            .select:not(:last-child) {
                margin-right: 17px;
            }
        }
    }

    .submit {
        flex: 1;
        display: flex;
        align-items: flex-end;
        .btn {
            background-color: #bc0e0e;
            color: #ffe8e8;
            border-radius: 5px;
            font-size: 18px;
            height: 56px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 20px;
            cursor: pointer;
            position: relative;

            .search-icon {
                margin-right: 14px;
            }
        }
    }

    .btn::after {
        transition: all 0.3s;
    }

    .btn::before,
    .btn::after {
        background: rgba($color: #dedede, $alpha: 0.3);
        content: '';
        position: absolute;
        border-radius: 6px;
    }

    .btn::after {
        height: 100%;
        left: 0;
        top: 0;
        width: 0;
    }

    .btn:hover:after {
        width: 100%;
    }
}
@media screen and (max-width: 960px) {
    .content {
        width: 90vw;
        .title {
            font-size: 20px;
            text-align: center;
            .sub-title {
                font-size: 16px;
            }
        }

        .form-box {
            flex-direction: column;
            .form {
                .select-box {
                    flex-wrap: wrap;
                    margin-bottom: 5px;
                    justify-content: space-between;
                    .select {
                        flex: none;
                        width: 49%;
                        flex-shrink: 0;
                        margin-bottom: 10px;
                    }

                    .select:not(:last-child) {
                        margin-right: 0;
                    }
                }
            }
        }

        .submit {
            .btn {
                margin-left: 0;
                margin-top: 15px;
                font-size: 16px;
            }
        }
    }
}
</style>
