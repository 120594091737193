<template>
    <div class="home">
        <div class="swiper">
            <div class="swiper-wrapper">
                <!-- Slides -->
                <div class="swiper-slide">
                    <img src="../assets/home/slide-1.jpg" alt="" />
                </div>
                <div class="swiper-slide">
                    <img src="../assets/home/slide-2.jpg" alt="" />
                </div>
            </div>
            <div class="swiper-pagination"></div>
        </div>
        <div class="search-block">
            <Search @search="search"></Search>
            <div class="about-description">
                <div class="title">
                    關於本會
                    <div class="red-line"></div>
                </div>
                <div class="text">
                    本工會自⺠國81年成立⾄今，以團結配音從業人員，保障會員權益，增進會員知能，協調勞資合作，謀求會員福利，改善會員生活為宗旨。
                    以加強會員間緊密互動、增進會員向⼼⼒，提昇會員之福祉為最高指導原則。<br />
                    不限戶籍或居住地，凡從事各種語言、音樂、效果及其他有關聲音之錄製工作，且年滿十八歲以上者，皆可申請加入會員，參與工會活動，享有會員福利。
                </div>
            </div>
        </div>
        <div class="btn-box">
            <div class="item about" @click="changeRoute('About')">
                <img class="desktop" src="../assets/home/about-bg.png" alt="" />
                <img
                    class="mobile"
                    src="../assets/home/about-bg-mobile.jpg"
                    alt=""
                />
                <div class="item-block"></div>
                <div class="text">關於工會</div>
            </div>
            <div class="item join" @click="changeRoute('JoinUs')">
                <img class="desktop" src="../assets/home/join-bg.png" alt="" />
                <img
                    class="mobile"
                    src="../assets/home/join-bg-mobile.jpg"
                    alt=""
                />
                <div class="item-block"></div>
                <div class="text">加入工會</div>
            </div>
        </div>
        <div class="info-box">
            <div class="content">
                <div class="info">
                    <div class="title">臺北市配音人員職業工會</div>
                    <div class="details">
                        <div
                            class="item"
                            v-for="item in info"
                            :key="item.label"
                        >
                            <div class="icon">
                                <img
                                    src="../assets/home/info-icon.png"
                                    alt=""
                                />
                            </div>
                            <div class="text">
                                {{ item.label }}<br />
                                {{ item.value }}
                            </div>
                        </div>
                    </div>
                    <div
                        class="fb-page"
                        data-href="https://www.facebook.com/profile.php?id=100075955962638"
                        data-tabs="timeline"
                        data-width=""
                        data-height=""
                        data-small-header="false"
                        data-adapt-container-width="true"
                        data-hide-cover="false"
                        data-show-facepile="true"
                    >
                        <blockquote
                            cite="https://www.facebook.com/profile.php?id=100075955962638"
                            class="fb-xfbml-parse-ignore"
                        >
                            <a
                                href="https://www.facebook.com/profile.php?id=100075955962638"
                                >台北市配音人員職業工會</a
                            >
                        </blockquote>
                    </div>
                </div>
                <iframe
                    class="map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28922.9900725421!2d121.50457246162355!3d25.021386962618823!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442abe78b78e1ff%3A0x52db476486a46af!2z6Ie65YyX5biC6YWN6Z-z5Lq65ZOh6IG35qWt5bel5pyD!5e0!3m2!1szh-TW!2stw!4v1638351401915!5m2!1szh-TW!2stw"
                    style="border: 0"
                    allowfullscreen=""
                    loading="lazy"
                ></iframe>
            </div>
        </div>
    </div>
</template>

<script>
import Swiper, { Autoplay, Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import Search from '@/components/Search.vue';

Swiper.use([Autoplay, Pagination]);

export default {
    name: 'Home',
    components: {
        Search,
    },
    data() {
        return {
            info: [
                { label: '地址', value: '105台北市 松山區南京東路三段305號5樓' },
                { label: '捷運', value: '南京復興站、小巨蛋站' },
                { label: '電話', value: '02-27170153' },
                { label: '傳真', value: '02-27173122' },
                { label: '專線', value: '0987675135' },
                { label: '服務時間：', value: '週一至週五 13:00~17:00（週六及例假日休息）' },
            ],
        };
    },
    mounted() {
        // eslint-disable-next-line no-new
        new Swiper('.swiper', {
            loop: true,
            pagination: {
                el: '.swiper-pagination',
            },
            autoplay: {
                delay: 5000,
                stopOnLastSlide: false,
                disableOnInteraction: false,
            },
        });
    },
    methods: {
        changeRoute(name) {
            this.$router.push({ name });
        },
        search(form) {
            this.$router.push({
                name: 'VoiceDatabase',
                query: {
                    gender: form.gender,
                    voiceType: form.voiceType,
                    langue: form.langue,
                    dubbingType: form.dubbingType,
                    text: form.text,
                    page: this.currentPage,
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.is-desktop {
    display: flex;
}
.is-mobile {
    display: none;
}
.home {
    .swiper {
        height: 704px;
        .swiper-slide {
            img {
                height: 704px;
                width: 100vw;
                object-fit: contain;
                background-color: black;
            }
        }
    }

    .search-block {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100vw;
        padding: 60px 0;
        background-image: url('../assets/home/description-bg.png');
        background-color: #BDA996;
        background-size: cover;
        background-position: 50% 50%;

        .about-description {
            display: flex;
            justify-content: center;
            width: 70vw;
            max-width: 900px;
            margin-top: 80px;

            .title {
                font-size: 20px;
                font-weight: bold;
                color: #111e23;
                margin-right: 60px;
                flex-shrink: 0;
            }

            .text {
                font-size: 16px;
                color: black;
            }
        }
    }

    .btn-box {
        width: 100vw;
        display: flex;

        .item {
            width: 50%;
            cursor: pointer;
            position: relative;

            img {
                width: 100%;
                height: 100%;
            }

            .desktop {
                display: block;
            }
            .mobile {
                display: none;
            }

            .item-block {
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.3);
                position: absolute;
                top: 0;
                left: 0;
                transition: width 0.3s ease;
            }

            .text {
                position: absolute;
                top: 26px;
                left: 58px;
                font-weight: bold;
                font-size: 35px;
                color: white;
                z-index: 2;
            }
        }

        .item:hover {
            .item-block {
                width: 0;
            }
        }
    }

    .info-box {
        width: 100vw;
        padding: 71px 0 98px 0;
        display: flex;
        justify-content: center;

        .content {
            width: 70%;
            max-width: 880px;
            display: flex;
        }

        .info {
            width: 50%;
            .title {
                font-size: 20px;
                font-weight: bold;
                color: #a90e0e;
                line-height: 58px;
                letter-spacing: 0.2em;
            }

            .details {
                margin-bottom: 25px;
            }

            .item {
                display: flex;

                .icon {
                    margin-right: 10px;
                    img {
                        width: 14px;
                    }
                }

                .text {
                    color: #bc0e0e;
                    font-size: 14px;
                }
            }
        }

        .map {
            width: 50%;
        }
    }
}

@media screen and (max-width: 960px) {
    .is-desktop {
        display: none;
    }
    .is-mobile {
        display: flex;
    }
    .home {
        .swiper {
            height: 300px;
            .swiper-slide {
                img {
                    height: 300px;
                    width: 100vw;
                    object-fit: contain;
                    background-color: black;
                }
            }
        }
        .search-block {
            .about-description {
                flex-direction: column;
                align-items: center;

                .title {
                    margin-right: 0;
                    margin-bottom: 26px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
            }
        }

        .btn-box {
            .item {
                .desktop {
                    display: none;
                }
                .mobile {
                    display: block;
                }

                .text {
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    font-weight: bold;
                    font-size: 17px;
                    color: white;
                    z-index: 2;
                }
            }
        }

        .info-box {
            padding: 35px 0;
            .content {
                width: 88vw;
                flex-direction: column;
            }
            .info {
                width: 100%;
                .title {
                    font-size: 16px;
                }
            }

            .map {
                width: 88vw;
                height: 88vw;
            }
        }
    }
}
</style>
