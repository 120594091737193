import { createStore } from 'vuex';

export default createStore({
    state: {
        hamburgerActive: false,
        routerListShow: false,
    },
    mutations: {
        changeHamburgerActive(state) {
            state.hamburgerActive = !state.hamburgerActive;
        },
        setHamburgerActive(state, value) {
            state.hamburgerActive = value;
        },
        setRouterListShow(state, value) {
            state.routerListShow = value;
        },
    },
    actions: {
    },
    modules: {
    },
});
