<template>
    <div class="footer bg-color-red-1">
        <div class="copy-right">© 2021 Copyright Inc. All rights reserved.</div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {};
    },
    computed: {},
    created() { },
    mounted() { },
    methods: {},
};
</script>

<style lang="scss" scoped>
.footer{
    height: 81px;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 25px;
    background-color: #A31919;
    position:absolute;
    bottom: 0;

    .copy-right {
        font-size: 14px;
        line-height: 32px;
        text-align: right;
        color: #ffffff;
    }
}

@media screen and (max-width: 960px) {
    .footer {
        justify-content: center;
    }
}
</style>
