<template>
    <div class="scroll-top" @click="scrollTop">
        <img src="../assets/back-top.png" alt="">
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {};
    },
    computed: {},
    created() { },
    mounted() { },
    methods: {
        scrollTop() {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },
    },
};
</script>

<style lang="scss" scoped>
.scroll-top{
    position: fixed;
    bottom: 100px;
    right: 30px;
    cursor: pointer;
    z-index: 1;

    img {
        width: 46px;
        height: 46px;
    }
}

@media screen and (max-width: 960px) {
    .scroll-top{
        display: none;
    }
}
</style>
