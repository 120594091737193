<template>
    <div class="nav">
        <div class="left">
            <router-link
                class="item"
                :class="{ active: $route.name == 'Home' }"
                :to="{ name: 'Home' }"
                ><img
                    src="../assets/nav/logo.png"
                    alt="臺北市配音人員職業工會"
                    class="logo"
            /></router-link>
        </div>
        <div class="right">
            <transition name="menu">
                <div
                    class="route-list"
                    v-show="hamburgerActive"
                    :class="{ 'show-router-list': routerListShow }"
                >
                    <router-link
                        class="item"
                        :class="{ active: $route.name == 'Home' }"
                        :to="{ name: 'Home' }"
                        ><font-awesome-icon class="home" icon="home" />
                        <div class="home-text">首頁</div></router-link
                    >
                    <router-link
                        class="item"
                        :class="{ active: $route.name == 'About' }"
                        :to="{ name: 'About' }"
                        >關於我們</router-link
                    >
                    <router-link
                        class="item"
                        :class="{ active: $route.name == 'JoinUs' }"
                        :to="{ name: 'JoinUs' }"
                        >加入我們</router-link
                    >
                    <router-link
                        class="item"
                        :class="{ active: $route.name == 'DataDownload' }"
                        :to="{ name: 'DataDownload' }"
                        >資料下載</router-link
                    >
                    <router-link
                        class="item"
                        :class="{ active: $route.name == 'VoiceDatabase' }"
                        :to="{ name: 'VoiceDatabase' }"
                        >聲音資料庫</router-link
                    >
                    <a class="item" href="https://vocustaiwan.fandom.com/zh-tw/wiki/台灣配音維基" target="_blank">台配維基</a>
                </div>
            </transition>
            <div
                id="hamburger"
                :class="{ open: hamburgerActive }"
                @click="clickHamburger"
            >
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
    components: {
    },
    data() {
        return {
        };
    },
    computed: {
        ...mapState(['hamburgerActive', 'routerListShow']),
    },
    created() {
        window.addEventListener('resize', this.handleRouterListShow);
    },
    mounted() {
        this.handleRouterListShow();
    },
    methods: {
        ...mapMutations(['changeHamburgerActive', 'setRouterListShow']),
        clickHamburger() {
            this.changeHamburgerActive();
        },
        handleRouterListShow() {
            if (window.innerWidth > 960) {
                this.setRouterListShow(true);
            } else {
                this.setRouterListShow(false);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../scss/guid-line.scss';

.show-router-list {
    display: flex !important;
}
.nav {
    width: 100vw;
    height: 88px;
    border-bottom: 3px solid #fe0000;
    position: fixed;
    z-index: 100;
    padding: 0 35px;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;

    .left {
        display: flex;

        .logo {
            // width: 44px;
            height: 46px;
        }
        .name {
            font-weight: bold;
            font-size: 22px;
            line-height: 45px;
        }
    }
    .right {
        .route-list {
            display: flex;
            .item {
                margin: 0 24px;
                font-size: 18px;
                color: #5e5e5e;
                text-decoration: none;
                display: flex;
                align-items: center;
                .home {
                    width: 23px;
                    height: 22px;
                }
                .home-text {
                    display: none;
                }
            }
            .item:hover {
                color: $red1;
            }

            .active {
                color: $red1;
            }
        }
    }
}

@media screen and (max-width: 960px) {
    .nav {
        padding: 0 24px;
        height: 62px;
        z-index: 100;
        .left {
            .logo {
                height: 31px;
            }
            .name {
                font-weight: bold;
                font-size: 16px;
                line-height: 30px;
            }
        }
        .right {
            .route-list {
                position: fixed;
                top: 62px;
                left: 0;
                z-index: 98;
                flex-direction: column;
                width: 100vw;
                border-bottom: 2px solid #fe0000;
                .item {
                    background-color: white;
                    margin: 0;
                    padding: 11px 28px;
                    .home-text {
                        display: block;
                        margin-left: 4px;
                    }
                }

                .item:not(:last-child) {
                    border-bottom: 1px solid #aeaeae;
                }
            }

            .menu-open {
                display: block;
            }

            .hamburger {
                display: block;
            }
        }

        #hamburger {
            display: block;
        }
    }

    .menu-enter-active,
    .menu-leave-active {
        transition: opacity 0.5s ease;
    }

    .menu-enter-from,
    .menu-leave-to {
        opacity: 0;
    }
}

//hamburger

#hamburger {
    width: 28px;
    height: 28px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    display: none;
}

#hamburger span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: #bc0e0e;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
}

#hamburger span:nth-child(1) {
    top: 0px;
}

#hamburger span:nth-child(2),
#hamburger span:nth-child(3) {
    top: 9px;
}

#hamburger span:nth-child(4) {
    top: 18px;
}

#hamburger.open span:nth-child(1) {
    top: 9px;
    width: 0%;
    left: 50%;
}

#hamburger.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

#hamburger.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

#hamburger.open span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
}
</style>
