import { createApp } from 'vue';
import Quasar from 'quasar/src/vue-plugin.js';;
import { library } from '@fortawesome/fontawesome-svg-core';
import { faHome, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import App from './App.vue';
import router from './router';
import store from './store';
import quasarUserOptions from './quasar-user-options';

library.add(faHome, faSearch);

createApp(App).use(Quasar, quasarUserOptions).use(store).use(router)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app');
