import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import store from '../store';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/about',
        name: 'About',
        component: () => import('../views/About.vue'),
    },
    {
        path: '/join-us',
        name: 'JoinUs',
        component: () => import('../views/JoinUs.vue'),
    },
    {
        path: '/data-download',
        name: 'DataDownload',
        component: () => import('../views/DataDownload.vue'),
    },
    {
        path: '/iframe-test',
        name: 'IframeTest',
        component: () => import('../views/IframeTest.vue'),
    },
    {
        path: '/voice-database',
        name: 'VoiceDatabase',
        component: () => import('../views/VoiceDatabase.vue'),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.afterEach(() => {
    store.commit('setHamburgerActive', false);
});

export default router;
