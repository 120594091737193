<template>
    <div class="app">
        <Nav></Nav>
        <div class="nav-block"></div>
        <router-view></router-view>
        <Footer></Footer>
        <div class="footer-block"></div>
        <ScrollTop></ScrollTop>
    </div>
</template>

<script>
import Nav from '@/components/Nav.vue';
import Footer from '@/components/Footer.vue';
import ScrollTop from '@/components/ScrollTop.vue';

export default {
    name: 'LayoutDefault',

    components: {
        Nav,
        Footer,
        ScrollTop,
    },

    setup() {
        return {
        };
    },
};
</script>

<style lang="scss">
@import 'scss/reset.scss';
@import 'scss/guid-line.scss';
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@400;500;700&display=swap');

* {
    box-sizing: border-box;
    font-family: 'Noto Sans TC', sans-serif;
}

.app {
    min-height: 100vh;
    position: relative;
}

.nav-block {
    height: 88px;
}
.footer-block{
    height: 81px;
}

@media screen and (max-width: 960px) {
    .nav-block {
        height: 62px;
    }
}
</style>
